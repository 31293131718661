<template>
  <!--
    Edit Calendar event
  -->
  <div class="onCallDutyEditEvent">
    <form>
      <div class="form-group">
        <label
          v-if="selectedEvent.role != 'Holiday'"
          for="roles"
          class="form-label"
        >{{ $t('customerNotification.roles') }}</label>
        <label
          v-else
          for="roles"
          class="form-label"
        >{{ 'Holiday' }}</label>
        <LoadingPlaceholder v-if="loadingRole" />
        <Multiselect
          v-else
          id="roles"
          v-model="selectedRole"
          tag-placeholder="Not found"
          placeholder="Select a Role"
          :options="roles"
          select-label=""
          :multiple="false"
          :taggable="true"
          :hide-selected="true"
          :searchable="false"
          :close-on-select="true"
          :custom-label="roleLabel"
          :disabled="!(authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
          class="mb-4"
        />
      </div>
      <div
        v-if="selectedEvent.role != 'Holiday'"
        class="form-group"
      >
        <label
          for="contacts"
          class="form-label"
        >{{ $t('installationDetailOperationView.contacts') }}</label>
        <LoadingPlaceholder v-if="loadingContacts" />
        <Multiselect
          v-else
          id="contacts"
          v-model="selectedContact"
          tag-placeholder="Not found"
          placeholder="Select a Contact"
          :options="contacts"
          select-label=""
          :multiple="false"
          :taggable="true"
          :hide-selected="true"
          :searchable="true"
          :close-on-select="true"
          :custom-label="customLabel"
          :disabled="!(authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
          class="mb-4"
        />
      </div>
      <template v-if="selectedContact">
        <div class="form-group">
          <label for="phone">{{ $t('phone') }}</label>
          <div style="display:flex;">
            <button
              slot="button"
              class="btn btn-success float-right mb-3 mr-3"
              @click="callContact"
            >
              <font-awesome-icon
                class="mr-1"
                icon="mobile"
              />
              <span>{{ $t('call') }}</span>
            </button>
            <input
              id="phone"
              v-model="selectedContact.phone"
              disabled
              class="form-control mb-3"
              type="text"
            >
          </div>
        </div>
      </template>
    </form>
    <div class="form-group">
      <label>{{ 'On' }}</label>
      <date-picker
        v-model="selectedDateFrom"
        :editable="true"
        :clearable="false"
        type="date"
        lang="en"
        format="DD.MM.YYYY"
        value-type="date"
        :first-day-of-week="1"
        class="ml-3"
        :disabled="selectedEvent.role == 'Holiday' || !(authenticationHasRole('admin') || authenticationHasRole('secondlevel'))"
      />
    </div>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import DatePicker from 'vue2-datepicker';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: 'OnCallDutyEditEvent',
  components: {
    Multiselect: () => import('vue-multiselect'),
    DatePicker
  },
   mixins: [
    dateTimeMixin,
    authenticationMixin
  ],
  props: {
    selectedEvent: {
      type: Object,
      required: true
    },
    roleColors: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      contacts: null,
      selectedContact: null,
      selectedRole: null,
      roles: null,
      loadingRole: true,
      loadingContacts: true,
      selectedDateFrom: null,
      selectedDateTo: null,
    }
  },
  watch: {
    selectedRole () {
      if(this.selectedEvent.role != 'Holiday') {
        this.getContacts();
      }
    }
  },
  created () {
    if(this.selectedEvent.role != 'Holiday') {
      this.getAlturosContactRoles();
    }
    else {
      this.roles = [];
      this.roles.push({name: this.selectedEvent.contactName });
      this.selectedRole = this.roles[0];
      this.loadingRole = false;
    }
    this.selectedDateFrom = this.selectedEvent.start;
    this.selectedDateTo = this.selectedEvent.end;
  },
   methods: {
     roleLabel ({ name }) {
      return `${name}`
    },
     customLabel ({ firstname, surname }) {
      return `${firstname} ${surname}`
    },
     getAlturosContactRoles () {
      this.loadingRole = true;
      this.axios.get(`/ApplicationSettings/GetApplicationSettingsByKey?key=alturos-contact-role`)
      .then((response) => {
        this.roles = [];
        response.data.forEach((el, index) => {
          this.roles.push({ name: el.value.split(';')[0], color: this.roleColors[index]});
        });

        this.selectedRole = this.roles.find(x => x.name == this.selectedEvent.role);
        this.loadingRole = false;
      });
    },
    getContacts () {
      this.selectedContact = null;
      this.loadingContacts = true;
      var rolesForReq = [ this.selectedRole.name ];

      this.axios.post(`/OnCallDuty/GetOnCallDutyContacts`, rolesForReq)
      .then((response) => {
        this.contacts = response.data;
        this.selectedContact = this.contacts.find(x => x.id == this.selectedEvent.contact);
      }).finally(() => {
        this.loadingContacts = false;
      });
    },
    deleteEvent () {
      this.$emit('setLoading', true);
      this.axios.delete(`/OnCallDuty/DeleteOnCallDuty?id=${this.selectedEvent.id}`)
      .then(() => {
        this.$emit('closeAndReload');
      });
    },
    updateEvent () {
      this.$emit('setLoading', true);
      var req = {
        id: this.selectedEvent.id,
        dateFrom: this.selectedDateFrom,
        skicon: this.selectedContact.id,
        skiconName: this.selectedContact.firstname + ' ' + this.selectedContact.surname,
        role: this.selectedRole.name,
        color: this.selectedRole.color
      };

      this.axios.put(`/OnCallDuty/UpdateOnCallDuty`, req)
      .then(() => {
        this.$emit('closeAndReload');
      });
    },
    callContact () {
      window.open('tel:' + this.selectedContact.phone);
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>